import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Kathy's Tomato Sauce" />
    <h1>Kathy's Tomato Sauce Recipe</h1>

    <p>My wife Kathy makes tomato sauce that our whole family enjoys. Her recipe is actually only
      slightly different than her mom's sauce, so it's been handed down. We use it to make
      pizza and also what my wife calls Damn Dip. It's also the dipping sauce we use for what we call
      Dip Dips (mozzarella breadsticks).
    </p>

    <p>Without further ado, here it is. Enjoy!</p>

    <h4>Ingredients</h4>

    <ul>
      <li>6 cans crushed tomato sauce (28 oz cans)</li>
      <li>1 can tomato paste (15 oz)</li>
      <li>2 Tbsp fresh minced garlic cloves or minced garlic from a jar</li>
      <li>1 large or 2 small onions, chopped</li>
      <li>1/3 bunch of fresh Italian parsley</li>
      <li>3/4 oz fresh oregano</li>
      <li>4 oz fresh basil</li>
    </ul>

    <h4>Instructions</h4>

    <ol>
      <li>Add all cans of crushed tomatoes and tomato paste to a large pot over medium heat</li>
      <li>Add garlic and onion</li>
      <li>Wash fresh parsley, oregano, and basil in strainer</li>
      <li>For parsley and oregano, pull off leaves from the stem and throw in sauce</li>
      <li>For basil, rip leaves into small-ish pieces and throw in sauce</li>
      <li>Once this is all added and sauce starts to bubble, turn down to simmer</li>
      <li>Continue to let simmer for 1.5 - 2 hours.</li>
    </ol>

    <h4>Notes:</h4>

    <ul>
      <li>Be sure to use Italian parsley, not the curly kind which has less flavor. Also be careful to not pick cilantro.
        Cilantro and parsley look very similar, are packaged in bunches in the same way, but have a different flavor, so don't
        substitue cilantro if you can't find parsley.
      </li>
      <li>Some people say this uses a lot of basil but I wouldn't have it any other way.</li>
      <li>Add more (or less) garlic as you see fit. Garlic wards off evil spirits.</li>
      <li>I use a little water to rinse out the cans and add the tomato water to the sauce. Waste not want not!</li>
      <li>Find a video of the recipe <a href="https://www.youtube.com/watch?v=IorWeMCo_Zs">here</a> originally
        made for our son Tony who requested that we document recipes he's had growing up so he can make them.</li>
    </ul>

  </Layout>
)

export default IndexPage
